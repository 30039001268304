<template>
  <div class="py-3">
    <h3 class="text-muted text-center">Cargando...</h3>
  </div>
</template>

<script>
/* import axios from 'axios'
import current from '@/login/settings.js' */

export default {
  name: 'Logged',
  mounted() {
    var redirect = this.$route.query.redirect
    if(typeof(redirect) !== 'undefined') {

      /* axios.get(current.version().endpoint + current.version().version + 'auth/redirect/' + redirect, { headers }).then( (result) => {
        var response = result.data.data
        window.location.href = response.redirect
      }) */
      
      switch (redirect) {
        case 'pay': window.location.href = 'https://pay.x-orsystem.com'; break;
        case 'store': window.location.href = 'https://x-orsystem.com'; break;
        case 'admin': window.location.href = 'https://admin.x-orsystem.com'; break;

        default: window.location.href = 'https://x-orsystem.com'; break;
      }
      
    } else window.location.href = 'https://x-orsystem.com';
  }
}
</script>